import { Message } from "element-ui"; 
import { sleep, startLoading, endLoading } from "@/utils/function"; 
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    name: "statistics",
    inject:['reload'],
    components: {},
    data() {
        return {
          // 选择的学校id
          school_id: "",
          // 选择的小游戏id
          game_id: "",
          // 选择的小游戏类型
          game_type_id: "",
          // 学校列表
          schoolList: [],
          // 小游戏列表
          gameList: [],
          // 小游戏类型列表
          gameTypeList: [],
          // 左侧功能项的选中值
          tapName: "exportGame",
          // 左侧功能项位置
          tabPosition: "left",
          // 右侧数据列表
          tableData: [],
          // 控制添加对话框的显示与隐藏
          AddVisible: false,
          // 当前页码
          currentPage: 1,
          // 一页显示多少条数据
          pageSize: 8,
          // 数据总条数
          total: 0,
          // 搜索关键词
          searchVal: "",
          form: {
            // 虚拟实验名称
            expName: "",
            // 虚拟实验ID
            expID: "",
          },
          // 通知内容
          noticeContent: "",
            // 登录查询数组   
          loginTableData: [
           
          ],
          //   登录搜索值
          searchValue:'',
          // 上传url
          uploadUrl: process.env.VUE_APP_DOMAIN_URL + "/backend/public/index.php/backStatistics/uploadExportCourseFile",
          // 上传excel 文件路径
          groupFilePath:'',
          // 文件列表
          fileList:[],


        };
    },
    //页面创建
    created() {
        let self = this;
        self.getData(); 
    },

    methods: {
        /**
         * @name: 下载模板文件
         * @author: camellia
         * @date: 2022-04-27
         */
        downloadImportTemplate()
        {
          var url = process.env.VUE_APP_DOMAIN_URL + "/backend/public/uploads/template/批量解锁虚拟实验模板.xlsx";
          window.open(url);
        },
        /**
         * @name: 导入成绩excel
         * @author: camellia
         * @date: 2022-04-27
         */
        importCourseExcel()
        {
          let self = this;
          let data = {
            filePath: self.groupFilePath,
          };
          // 请求数据
          self.axios
            .post("/index.php/backStatistics/createCourseAndProjectAndGroup", data)
            .then((result) => {
              // 关闭loading
              nprogressStart();
              self.$Loading.hide();
              self.$Tips({
                // 消息提示内容
                message:result.data.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType:'success',
                // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                displayTime:1500
              })
            })
            .catch((err) => {});
        },
        /**
         * @name: 上传前校验
         * @author: camellia
         * @date: 2021-03-05
         */
        markHandleBeforeUpload(file) 
        {
          const self = this;
          var array = file.name.split(".");
          if (array.length < 2) 
          {
            // Message.error("文件错误！");
            self.fileList = [];
            self.$Tips({
              // 消息提示内容
              message:'文件错误',
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'warning',
              // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
              button:[
                  {
                      type:1,
                      text:'知道了'
                  }
              ]
            })
            return;
          }
          var ext = array[array.length - 1];
          if (ext != "xls" && ext != "xlsx") 
          {
            // Message.error("文件格式为xls，xlsx，请重试！");
            self.fileList = [];
            self.$Tips({
              // 消息提示内容
              message:'文件格式为xls，xlsx，请重试',
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'warning',
              // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
              button:[
                  {
                      type:1,
                      text:'知道了'
                  }
              ]
            })
            return;
          }
          const isLt2M = file.size / 1024 / 1024 < 5;
          if (!isLt2M) 
          {
            // Message.error("上传头像图片大小不能超过 5MB!");
            self.fileList = [];
            self.$Tips({
              // 消息提示内容
              message:'上传头像图片大小不能超过 5MB',
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'warning',
              // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
              button:[
                  {
                      type:1,
                      text:'知道了'
                  }
              ]
            })
            return;
          }
        },
        /**
         * @name: 上传文件数量校验
         * @author: camellia
         * @date: 2021-03-05
         * @param:	file	json	上传的文件对象
         * @param:	fileList	json	现存的文件列表
         */
        handleExceed(files, fileList) 
        {
          // Message.warning(
          //   `当前限制选择 1 个文件，本次选择了 ${
          //     files.length
          //   } 个文件，共选择了 ${files.length + fileList.length} 个文件`
          // );
          this.$Tips({
            // 消息提示内容
            message:`当前限制选择 1 个文件，本次选择了 ${
                  files.length
                } 个文件，共选择了 ${files.length + fileList.length} 个文件`,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType:'warning',
            // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
            button:[
                {
                    type:1,
                    text:'知道了'
                }
            ],
        })
        },
        /**
         * @name: 删除文件之前执行的方法
         * @author: camellia
         * @date: 2021-03-05
         * @param:	file	json	删除的文件对象
         * @param:	fileList	json	现存的文件列表
         */
        beforeRemove(file, fileList) {
          return this.$confirm(`确定移除 ${file.name}？`);
        },
        /**
         * @name: 删除文件
         * @author: camellia
         * @date: 2021-03-05
         * @param:	file	json	删除的文件对象
         * @param:	fileList	json	现存的文件列表
         */
        handleRemove(file, fileList) 
        {
          // console.log(file);
          // console.log(fileList);
        },
        /**
         * @name: 上传成绩excel成功
         * @author: camellia
         * @date: 2021-03-01
         * @param:	res	json	上传图片返回信息
         */
        markHandleAvatarSuccess(res) 
        {
          const self = this;
          if (res.code > 0) 
          {
            self.groupFilePath = res.path;
            // console.log(self.groupFilePath);
            // Message.success(res.msg);
            this.$Tips({
              // 消息提示内容
              message:res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'success',
              // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
              displayTime:1500
          })
          } 
          else 
          {
            // Message.error(res.msg);
            this.$Tips({
              // 消息提示内容
              message:res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'warning',
              // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
              displayTime:1500
          })
          }
        },
        /**
         * @name: 登录
         * @author: camellia
         * @date: 2021-12-08 
         */
        login(id)
        {
            let self = this;
            let data = {
              id: id,
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            self.axios
              .post("/index.php/backStatistics/loginManager", data)
              .then((res) => {
                if (res.data.code > 0) 
                {
                  this.$Tips({
                    // 消息提示内容
                    message:res.data.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'success',
                    // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                    displayTime:1500
                  })
                  // location.reload()
                  self.reload();
                }
                // 关闭loading
                nprogressClose();
                self.$Loading.hide();
              });
        },
        /**
         * @name: 查找教师列表
         * @author: camellia
         * @date: 2021-12-08 
         */
        searchTeacherList()
        {
            let self = this;
            if(self.searchValue == '')
            {
                // Message.warning("请填写教师信息！");
                this.$Tips({
                  // 消息提示内容
                  message:'请填写教师信息',
                  // 消息提示类型（success-成功,warning-警告/失败）
                  messageType:'warning',
                  // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                  displayTime:1500
              })
                return;
            }
            let data = {
              searchValue: self.searchValue,
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            self.axios
              .post("/index.php/backStatistics/getTeacherList", data)
              .then((res) => {
                if (res.data.code > 0) 
                {
                  self.loginTableData = res.data.list;
                }
                // 关闭loading
                nprogressClose();
                self.$Loading.hide();
              });
        },
        /**
         * @name: 发送通知公告
         * @author: camellia
         * @date: 2021-12-08 
         */
        sendNotice()
        {
            let self = this;
            let data = {
              noticeValue: self.noticeContent
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            self.axios
              .post("/index.php/backStatistics/sendNotice", data)
              .then((res) => {
                if (res.data.code > 0) 
                {
                  // Message.success("操作成功");
                  this.$Tips({
                    // 消息提示内容
                    message:res.data.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'success',
                    displayTime:1500
                })
                }
                // 关闭loading
                nprogressClose();
                self.$Loading.hide();
              });
        },
        /**
         * @name: 获取页面初始数据
         * @author: LANXINYU
         * @date: 20211122
         */
        getData()
        {
            let self = this;
            self.tapName = self.$route.query.tapName?self.$route.query.tapName:'exportGame';
            if(self.tapName=='exportGame')
            {
                self.GetGame();
            }
            else
            {
                self.getReportWebList();
            }
        },
        /**
         * @name: 获取学校数据
         * @author: LANXINYU
         * @date: 20211103
         */
        GetSchool(school_name)
        {
            let self = this;
            self.axios
              .get("/index.php/backother/getSchoolList?school_name=" + school_name)
              .then((result=>{
                  self.schoolList = result.data.data;
              }))
        },
        /**
         * @name: 获取小游戏列表
         * @author: LANXINYU
         * @date: 20211103
         */
        GetGame()
        {
            let self = this;
            self.axios
              .get("/index.php/backStatistics/getGameList")
              .then((result) => {
                self.gameList = result.data.data;
              });
        },
        /**
         * @name: 获取小游戏类型列表
         * @author: LANXINYU
         * @date: 20211103
         */
        GetGameType()
        {
            let self = this;
            if(self.game_id)
            {
                self.axios
                  .get(
                    "/index.php/backStatistics/getGameTypeList?     game_id=" +
                      self.game_id
                  )
                  .then((result) => {
                    self.gameTypeList = result.data.data;
                  });
            }
            else
            {
                // Message.warning('请先选择小游戏');
                this.$Tips({
                  // 消息提示内容
                  message:"请先选择小游戏",
                  // 消息提示类型（success-成功,warning-警告/失败）
                  messageType:'warning',
                  button:[
                      {
                          type:1,
                          text:'知道了'
                      }
                  ]
              })
            }
        },
        /**
         * @name: 导出小游戏数据
         * @author: LANXINYU
         * @date: 20211103
         */
        exportMiniGameData()
        {
            var self = this;
            if (self.game_type_id == "" || self.game_id == '' || self.school_id == '') 
            {
                // Message.warning("参数不能为空！");
                this.$Tips({
                  // 消息提示内容
                  message:"参数不能为空",
                  // 消息提示类型（success-成功,warning-警告/失败）
                  messageType:'warning',
                  button:[
                      {
                          type:1,
                          text:'知道了'
                      }
                  ]
              })
                return;
            }
            var url = process.env.VUE_APP_DOMAIN_URL + 
              "/backend/public/index.php/backStatistics/exportMiniGameData?school_id=" +
              self.school_id +
              "&game_id=" +
              self.game_id +
              "&game_type_id=" +
              self.game_type_id;

            window.open(url);
        },
        /**
         * @name: 点击左侧功能项
         * @author: LANXINYU
         * @date: 20211118
         */
        handleTapClick(tap)
        {
            let self = this;
            self.$router.push("/manager/statistics?currentPage="+self.currentPage+"&searchVal="+self.searchVal+"&tapName="+tap.name);
            if(self.tapName=='editWeb')
            {
                self.getReportWebList();
            }
            else
            {
                self.GetGame();
            }
        },
        /**
         * @name: 获取申报网站数据列表
         * @author: LANXINYU
         * @date: 20211118
         */
        getReportWebList()
        {
            let self = this;
            self.currentPage = self.$route.query.currentPage?self.$route.query.currentPage-0:1;
            self.searchVal = self.$route.query.searchVal?self.$route.query.searchVal:'';
            self.axios
                .get("/index.php/backStatistics/getReportWebList?currentPage="+self.currentPage+"&searchVal="+self.searchVal)
                .then((res)=>{
                    self.tableData = res.data.data;
                    if(!self.searchVal)
                    {
                        self.total = res.data.total;
                    }
                    else
                    {
                        self.total = res.data.data.length;
                    }
                })
        },
        /**
         * @name: 点击升级第二版
         * @author: LANXINYU
         * @date: 20211118
         */
        upLevel2(id)
        {
            let self = this;
            self.axios
                .post("/index.php/backStatistics/upLevel2?id="+id)
                .then((res)=>{
                  self.getReportWebList();
                })
        },
        /**
         * @name: 编辑申报网站
         * @author: LANIXNYU
         * @date: 20211118
         */
        handleEditWeb(row)
        {
            let self = this;
            self.$router.push('/teacher/editReportWeb/'+row.id+'/'+row.detail_id)
        },
        /**
        * @name: 展示添加对话框
        * @author: LANXINYU
        * @date: 20211119
        */
        showAddDialog()
        {
            let self =this;
            self.AddVisible=true;
        },
        /**
        * @name: 添加申报网站内容
        * @author: LANXINYU
        * @date: 20211119
        */
        AddVirtual()
        {
            let self = this;
            self.axios
                .get("/index.php/backStatistics/addReportWeb?exp_name="+self.form.expName+'&exp_id='+self.form.expID)
                .then((res)=>{
                    if(res.data.code>0)
                    {
                        self.AddVisible = false;
                        self.form.expName = '';
                        self.form.expID = '';
                        self.getReportWebList();
                        // Message.success('添加成功')
                        this.$Tips({
                          // 消息提示内容
                          message:'添加成功',
                          // 消息提示类型（success-成功,warning-警告/失败）
                          messageType:'success',
                          displayTime:1500
                      })
                    }
                })
        },
        /**
        * @name: 分页获取申报网站列表
        * @author: LANXINYU
        * @date: 20211119
        */
        handleCurrentChange(val)
        {
            let self = this;
            self.currentPage=val;
            self.$router.push("/manager/statistics?currentPage="+self.currentPage+"&tapName=editWeb");
            self.getReportWebList();
        },
        /**
        * @name: 搜索申报网站内容
        * @author: LANXINYU
        * @date: 20211119
        */
        searchReportWeb()
        {
            let self = this;
            self.currentPage = 1;
            self.$router.push("/manager/statistics?currentPage="+self.currentPage+"&searchVal="+self.searchVal+"&tapName=editWeb");
            // console.log(self.total);
            self.getReportWebList();
        },
        /**
        * @name: 清空搜索框，重新获取数据
        * @author: LANXINYU
        * @date: 20211119
        */
        clearSearch()
        {
            let self =this;
            self.$router.push("/manager/statistics?currentPage=1&searchVal=&tapName=editWeb");
            self.getReportWebList();
        },
        /**
        * @name: 删除申报网站
        * @author: LANXINYU
        * @date: 20211119
        */
        handleDeleteWeb(id)
        {
            let self = this;
            self.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                self.axios
                    .get("/index.php/backStatistics/deleteReportWeb?id="+id)
                    .then((res)=>{
                        if(res.data.code>0)
                        {
                            self.getReportWebList();
                            // Message.success('删除成功')
                            this.$Tips({
                              // 消息提示内容
                              message:'删除成功',
                              // 消息提示类型（success-成功,warning-警告/失败）
                              messageType:'success',
                              displayTime:1500
                          })
                        }
                    })
              }).catch(() => {
                // Message.info('已取消删除')
              });
            
        }
    }
};