<template>
    <el-card>
    <div class="selectSchool">
        <div class="download-button" @click="downloadImportTemplate()" style="cursor:pointer;color: #409eff;" >
                    <i class="el-icon-bottom" ></i>点击下载模板
                </div><br>
                <div class="download-title">
                    导入excel之后需要点击确定后，后台自动根据excel中对应的内容自动创建课程，项目，群组，并将其关联在一起
                </div><br>
                <div>
                    <el-upload
                        class="upload-demo"
                        :action="uploadUrl"
                        :on-success="markHandleAvatarSuccess"
                        :on-remove="handleRemove"
                        :before-remove="beforeRemove"
                        :limit="1"
                        :on-exceed="handleExceed"
                        :before-upload="markHandleBeforeUpload"
                        :file-list="fileList">
                        <el-button size="small" class="themeButton">点击上传文件</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件，且不超过5MB</div>
                    </el-upload>
                </div>
                <br>
                <div @click="importCourseExcel"> 
                    <el-button class="themeButton" size="mini">确定</el-button>
                </div>
    </div>
</el-card>
</template>
<style lang="scss" >

     @import "@/assets/css/manager/other/batchCreate.scss";
</style>
<script>
    import batchCreate from "@/assets/js/manager/other/batchCreate.js";
    export default {
        ...batchCreate
    };
</script>